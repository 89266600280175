import { render, staticRenderFns } from "./Evaluasi.vue?vue&type=template&id=0106b3f0"
import script from "./Evaluasi.vue?vue&type=script&lang=js"
export * from "./Evaluasi.vue?vue&type=script&lang=js"
import style0 from "./Evaluasi.vue?vue&type=style&index=0&id=0106b3f0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports